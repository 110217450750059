import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      © COPYRIGHT 2023 •️ ALL RIGHTS RESERVED • MATTER CONSULTING
    </div>
  );
}

export default Footer;
