import React, { useState } from "react";
import Card from "./Card";
import "./SearchSystem.css";

//fetching the API
function SearchSystem(props) {
  //list imported from Profile.js
  const list = props.list;

  //search data with Project name and ProjectId BOTH
  const [filteredName, setFilteredName] = useState([]);
  const onCallBackProjectNameAndJobSelected = (e) => {
    e.preventDefault();
    const value =
      e.currentTarget.children[1].value &&
      e.currentTarget.children[1].value[0].toUpperCase() +
        e.currentTarget.children[1].value.slice(1);

    const filteredData = list.filter((element) => {
      return (
        element.ProjectNumber.toString().includes(value) +
        element.ProjectName.toString().includes(value)
      );
    });
    setFilteredName(filteredData);
  };

  //

  //current active job selected from the selected jobs
  const [activeJob, setActiveJob] = useState();
  const callbackClick = (finalName, index) => {
    setActiveJob(finalName);
    props.onSelect(filteredName[index]);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center">
        <form
          className="w-96 border border-black rounded-full p-2 flex justify-between mb-10"
          onSubmit={onCallBackProjectNameAndJobSelected}>
          <p></p>
          <input
            type="text"
            placeholder="Enter Project Name/ Number"
            className="inputt"
          />

          <button className="bg-black text-white border rounded-full w-24 p-2 hover:bg-[#e76929]">
            Search
          </button>
        </form>
      </div>
      <div className="grid">
        {filteredName.map((names, index) => (
          <Card
            onJobSelect={() => callbackClick(names, index)}
            key={names.ProjectNumber}
            ProjectManagerName={names.ProjectManagerName}
            ProjectName={names.ProjectName}
            ProjectNumber={names.ProjectNumber}
            ClientName={names.ClientName}
            ProjectStatusName={names.ProjectStatusName}
            ProjectStatusID={names.ProjectStatusID}
            MelbOrAdel={names.CustomFields[0].Value}
            currentJob={activeJob}
          />
        ))}
      </div>
    </div>
  );
}

export default SearchSystem;
