import React from "react";
import "../Pages/Sections.css";

function Loader(props) {
  return (
    <div
      className="p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-green-200 dark:text-green-800 flex items-center"
      role="alert">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="gem"
        className="w-4 h-4 mr-2 fill-current"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512">
        <path
          fill="currentColor"
          d="M485.5 0L576 160H474.9L405.7 0h79.8zm-128 0l69.2 160H149.3L218.5 0h139zm-267 0h79.8l-69.2 160H0L90.5 0zM0 192h100.7l123 251.7c1.5 3.1-2.7 5.9-5 3.3L0 192zm148.2 0h279.6l-137 318.2c-1 2.4-4.5 2.4-5.5 0L148.2 192zm204.1 251.7l123-251.7H576L357.3 446.9c-2.3 2.7-6.5-.1-5-3.2z"></path>
      </svg>
      <span className="font-medium">{props.loaderType}</span>
    </div>
  );
}

export default Loader;
