import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import MobileNavbar from "../Components/MobileNavbar";
import Navbar from "../Components/Navbar";
import AdminDocs from "../Pages/AdminDocs";
import Concept from "../Pages/Concept";
import Construction from "../Pages/Construction";
import DesignDocs from "../Pages/DesignDocs";
import Profile from "../Pages/Profile";
import QA from "../Pages/QA";
import ReceivedDocs from "../Pages/ReceivedDocs";
import { callApi } from "../helpers";
import SearchSystem from "../Components/SearchSystem";
import MonthlyReporting from "./MonthlyReporting";

function Dashboard() {
  const [jobs, setJobs] = useState([]);
  const [currentJob, setCurrentJob] = useState();
  const location = useLocation();

  const onJobSelected = (job) => {
    setCurrentJob(job);
  };
  useEffect(() => {
    callApi(
      "GET",
      "/Projects?page=1&pageSize=50000&includeCustomFields=true"
    ).then((res) => {
      setJobs(res.data);
    });
  }, []);

  useEffect(() => {
    const projectData = [];
    (async () => {
      await Promise.all([
        callApi(
          "GET",
          "/Projects?page=1&pageSize=50000&includeCustomFields=true"
        ).then((res) => {
          projectData.push(...res.data);
        }),
        callApi(
          "GET",
          "/Projects?page=2&pageSize=50000&includeCustomFields=true"
        ).then((res) => {
          projectData.push(...res.data);
        }),
      ]);
      setJobs(projectData);
    })();
  }, []);
  console.log(jobs);
  return (
    <div>
      <Navbar />
      <MobileNavbar />
      <div className="full">
        <div className="left">
          {location.pathname !== "/dashboard/profile" ? (
            <SearchSystem list={jobs} onSelect={onJobSelected} />
          ) : null}
        </div>
        <Routes>
          <Route
            path="/profile"
            element={<Profile list={jobs} currentJob={currentJob} />}
          />
          <Route
            path="admin-docs"
            element={<AdminDocs list={jobs} currentJob={currentJob} />}
          />
          <Route
            path="concept"
            element={<Concept list={jobs} currentJob={currentJob} />}
          />
          <Route
            path="construction"
            element={<Construction list={jobs} currentJob={currentJob} />}
          />
          <Route
            path="design-docs"
            element={<DesignDocs list={jobs} currentJob={currentJob} />}
          />
          <Route
            path="qa"
            element={<QA list={jobs} currentJob={currentJob} />}
          />
          <Route
            path="received-docs"
            element={<ReceivedDocs list={jobs} currentJob={currentJob} />}
          />
          <Route path="monthly-reporting" element={<MonthlyReporting />} />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
