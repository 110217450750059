import { Buffer } from "buffer";
import { useState } from "react";

//This file is create to use the code once and reuse it again and again inside our components.

export function generateToken() {
  const decodeAuth =
    process.env.REACT_APP_PROJECTWORKS_API_CONSUMER_KEY +
    ":" +
    process.env.REACT_APP_PROJECTWORKS_API_CONSUMER_SECRET;
  var encodedAuth = Buffer.from(decodeAuth).toString("base64");
  return encodedAuth;
}

//method: GET POST DELETE PUT
//URL is the url of api
//body : data to be posted with POST METHOD

export async function callApi(method, url) {
  const token = generateToken();
  const response = await fetch(
    "https://api.projectworksapp.com/api/v1.0" + url,
    {
      method: method,
      headers: {
        Authorization: "Basic " + token,
      },
    }
  );
  const data = await response.json();
  return { status: response.status, data: data };
}

export async function uploadFileDocx(type, path, file_name) {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/upload-file-docx",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: type,
          path: path,
          file_name: file_name,
        }),
      }
    );
    const status = response.status;

    if (status === 201) {
      const responseData = await response.json();
      return responseData.data;
    } else {
      throw new Error("Error uploading file");
    }
  } catch {}
}
