import React, { useState } from "react";
import "./MobileNavbar.css";
import Logo from "../Icons/MatterLogo.svg";
import Menu from "../Icons/BurgerMenu.svg";
import MenuClose from "../Icons/MenuClose.svg";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../Authentication/AuthContext";

import Logoutt from "../Icons/Logoutt.svg";

function MobileNavbar() {
  const [toggle, setToggle] = useState(false);
  const [toggleLinks, setToggleLinks] = useState(false);
  const [linkActive, setLinkActive] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();

  function handleLogout() {
    setError("");
    try {
      logout();
      navigate("/");
    } catch {
      setError("Failed to Log out");
    }
  }

  const handleClick = () => {
    setToggle(!toggle);
    setToggleLinks(!toggleLinks);
    setLinkActive(!linkActive);
  };

  return (
    <div className="PhoneScreen">
      <div className="NavHandler">
        <img src={Logo} className="w-48" />
        {toggle ? (
          <img src={Menu} className="h-7 " onClick={handleClick} />
        ) : (
          <img src={MenuClose} className="h-7 " onClick={handleClick} />
        )}
      </div>
      {!toggleLinks ? (
        <div className="NavLinks">
          <Link className="Link" to="/dashboard/profile" onClick={handleClick}>
            Profile
          </Link>
          <Link
            className="Link"
            to="/dashboard/received-docs"
            onClick={handleClick}>
            Received Docs
          </Link>
          <Link
            className="Link"
            to="/dashboard/admin-docs"
            onClick={handleClick}>
            Admin Docs
          </Link>
          <Link className="Link" to="/dashboard/concept" onClick={handleClick}>
            Concept
          </Link>
          <Link
            className="Link"
            to="/dashboard/design-docs"
            onClick={handleClick}>
            Design Docs
          </Link>
          <Link
            className="Link"
            to="/dashboard/construction"
            onClick={handleClick}>
            Construction
          </Link>
          <Link className="Link" to="/dashboard/qa" onClick={handleClick}>
            QA
          </Link>
          <Link className="Link2" onClick={handleLogout}>
            Log Out
          </Link>
        </div>
      ) : null}
    </div>
  );
}

export default MobileNavbar;
