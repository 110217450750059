import React from "react";

function NestedFolderDisable(props) {
  const selectJob = () => {
    alert("Select a job before creating Folders");
  };
  return (
    <div className="flex space-x-5 ">
      <p className="upload">Upload Docs</p>
      <button
        className="bg-black text-white border rounded-full w-40 p-2 hover:bg-red-700"
        onClick={selectJob}>
        Create Folders
      </button>
    </div>
  );
}

export default NestedFolderDisable;
