import React from "react";
import "./UploadFile.css";
import upload from "../Icons/upload.svg";

function UploadFileDisable(props) {
  const onFileSelect = (e) => {
    const file = e.target.files[0];
    props.onUpload(file);
  };

  const showAlert = () => {
    alert("Please select a job first");
  };

  return (
    <div className="outer" onClick={showAlert}>
      <p className="fileName">{props.title}</p>
      <div className="flex  bg-grey-lighter">
        <label className="label">
          <img src={upload} width={30} />

          <input class="hidden" />
        </label>
      </div>
    </div>
  );
}

export default UploadFileDisable;
