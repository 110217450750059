import React, { useRef, useState, useEffect } from "react";
import Footer from "../Components/Footer";
import Loader from "../Components/Loader";
import NestedFolderDisable from "../Components/NestedFolderDisable";
import UploadFile from "../Components/UploadFile";
import UploadFileDisable from "../Components/UploadFileDisable";
import UploadSuccess from "../Components/UploadSuccess";
import "./Sections.css";

const addZeros = (num) => {
  return (num < 10 ? "0" : "") + num;
};

const fullDate =
  addZeros(new Date().getDate()) +
  " " +
  new Date().toLocaleString("default", {
    month: "long",
  }) +
  " " +
  new Date().getFullYear();

function QA(props) {
  const [uploadFileSpinner, setUploadFileSpinner] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const [uploadFolderSpinner, setUploadFolderSpinner] = useState(false);
  const [isFolderCreated, setIsFolderCreated] = useState(false);

  const jobs = props.list;
  const currentJob = props.currentJob;

  useEffect(() => {
    //clear the upload status after 3 seconds only if true
    let timer = null;
    if (isUploaded) {
      timer = setTimeout(() => {
        setIsUploaded(false);
        setIsFolderCreated(false);
        setUploadFileSpinner(false);
        setUploadFolderSpinner(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isUploaded]);

  const createNestedFolders = () => {
    const Year = currentJob.ProjectNumber.slice(0, 2);

    const ProjectNumber = currentJob.ProjectNumber;
    const ProjectPlace =
      currentJob.CustomFields[0].Value === null ||
      currentJob.CustomFields[0].Value === "1"
        ? "M"
        : "A";
    if (!currentJob) {
      return;
    }
    setUploadFolderSpinner(true);
    fetch(process.env.REACT_APP_API_URL + "/create-multiple-folders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        paths: [
          `${Year}/${ProjectNumber}${ProjectPlace}/Admin/Proposal`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Admin/QA/Checklists`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Admin/QA/Drawing Reviews`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Admin/QA/Revizto Report`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Admin/QA/Safety in Design`,
          `${Year}/${ProjectNumber}${ProjectPlace}/CAD/Received`,
          `${Year}/${ProjectNumber}${ProjectPlace}/CAD/Matter/Issued`,
          `${Year}/${ProjectNumber}${ProjectPlace}/CAD/Matter/Current PDF`,
          `${Year}/${ProjectNumber}${ProjectPlace}/CAD/Matter/Links`,
          `${Year}/${ProjectNumber}${ProjectPlace}/CAD/Matter/Mark-ups`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Engineering/Calculations`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Engineering/Certifications`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Engineering/Civil`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Engineering/Correspondance/Letters`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Engineering/Correspondance/Meeting Minutes`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Engineering/Correspondance/Reports`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Engineering/Design brief`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Engineering/Geotechnical`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Engineering/Site Inspection Reports`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Engineering/Sketches`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Engineering/Specification`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Photos`,
          `${Year}/${ProjectNumber}${ProjectPlace}/Shop Drawings`,
        ],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {})
      .then((res) => {
        setIsFolderCreated(true);
        setUploadFolderSpinner(false);
      });
  };

  const uploadFile = (file) => {
    const Year = currentJob.ProjectNumber.slice(0, 2);

    if (!currentJob) {
      return;
    }
    setUploadFileSpinner(true);
    const formData = new FormData();
    formData.append(
      "path",
      `${Year}/${currentJob.ProjectNumber}${
        currentJob.CustomFields[0].Value === null ||
        currentJob.CustomFields[0].Value === "1"
          ? "M"
          : "A"
      }/Admin/QA/Checklists`
    );
    formData.append("file", file);

    fetch(process.env.REACT_APP_API_URL + "/upload-file", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {})
      .then((res) => {
        setIsUploaded(true);
        setUploadFileSpinner(false);
      }); //refreseh page
  };

  const uploadFile2 = (file) => {
    const Year = currentJob.ProjectNumber.slice(0, 2);

    if (!currentJob) {
      return;
    }
    setUploadFileSpinner(true);
    const formData = new FormData();
    formData.append(
      "path",
      `${Year}/${currentJob.ProjectNumber}${
        currentJob.CustomFields[0].Value === null ||
        currentJob.CustomFields[0].Value === "1"
          ? "M"
          : "A"
      }/Admin/QA/Safety in Design`
    );
    formData.append("file", file);

    fetch(process.env.REACT_APP_API_URL + "/upload-file", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {})
      .then((res) => {
        setIsUploaded(true);
        setUploadFileSpinner(false);
      }); //refreseh page
  };

  const uploadFile3 = (file) => {
    const Year = currentJob.ProjectNumber.slice(0, 2);

    if (!currentJob) {
      return;
    }
    setUploadFileSpinner(true);
    const formData = new FormData();
    formData.append(
      "path",
      `${Year}/${currentJob.ProjectNumber}${
        currentJob.CustomFields[0].Value === null ||
        currentJob.CustomFields[0].Value === "1"
          ? "M"
          : "A"
      }/Admin/QA/Drawing Reviews`
    );
    formData.append("file", file);

    fetch(process.env.REACT_APP_API_URL + "/upload-file", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {})
      .then((res) => {
        setIsUploaded(true);
        setUploadFileSpinner(false);
      }); //refreseh page
  };

  return (
    <div className="right ">
      {!isUploaded && uploadFileSpinner ? (
        <Loader loaderType="Uploading.." />
      ) : null}
      {isUploaded && !uploadFileSpinner ? (
        <UploadSuccess successName="uploaded successfully" />
      ) : null}
      {!isFolderCreated && uploadFolderSpinner ? (
        <Loader loaderType="Creating Folders.." />
      ) : null}
      {isFolderCreated && !uploadFolderSpinner ? (
        <UploadSuccess successName="Folders Created Successfully" />
      ) : null}
      {!currentJob ? (
        <NestedFolderDisable />
      ) : (
        <div className="flex space-x-5">
          <p className="upload">Upload Docs</p>
          <button
            onClick={createNestedFolders}
            className="bg-black text-white border rounded-full w-40 p-2 hover:bg-[#e76929]">
            Create Folders
          </button>
        </div>
      )}

      {!currentJob ? (
        <div className="uploadSection">
          <UploadFileDisable title="VERIFY ENGINEERING CHECKLIST" />
          <UploadFileDisable title="VERIFY BIM CHECKLIST" />
          <UploadFileDisable title="VERIFY DRAWING REVIEW" />
          <UploadFileDisable title="VERIFY SAFETY IN DESIGN" />
        </div>
      ) : (
        <div className="uploadSection">
          <UploadFile
            title="VERIFY ENGINEERING CHECKLIST"
            onUpload={uploadFile}
          />
          <UploadFile title="VERIFY BIM CHECKLIST" onUpload={uploadFile} />
          <UploadFile title="VERIFY DRAWING REVIEW" onUpload={uploadFile3} />
          <UploadFile title="VERIFY SAFETY IN DESIGN" onUpload={uploadFile2} />
        </div>
      )}
      <Footer />
    </div>
  );
}

export default QA;
