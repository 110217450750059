import "./App.css";
import { Routes, Route, Router } from "react-router-dom";
import Login from "./Authentication/Login";
import NotFound from "./Pages/NotFound";
import Dashboard from "./Pages/Dashboard";
import { AuthProvider } from "./Authentication/AuthContext";
import SignUp from "./Authentication/SignUp";
import PrivateRoute from "./Authentication/PrivateRoute";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route
          path="dashboard/*"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route exact path="signup" element={<SignUp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
