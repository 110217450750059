import React from "react";
import "./SingleDocDownload.css";

function SingleDocDownload(props) {
  return (
    <div>
      <button className="btn" onClick={props.onClick}>
        {props.singleDocName}
      </button>
    </div>
  );
}

export default SingleDocDownload;
