import React from "react";
import { useAuth } from "../Authentication/AuthContext";
import Footer from "../Components/Footer";
import "./Profile.css";

function Profile() {
  const { currentUser } = useAuth();
  const email = currentUser.email;
  const name = email.substring(0, email.indexOf("."));
  const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1);
  return (
    <div className="center">
      <div className="m-20 text-center">
        <p className="text-6xl">
          Hi <span className="userName">{nameCapitalized}</span>,
        </p>
        <p className="text-3xl "> Glad to see you back</p>
        <p className="text-3xl">How are you?</p>
      </div>
      <Footer />
    </div>
  );
}

export default Profile;
