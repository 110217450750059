import React, { useState, useEffect } from "react";

import "./Card.css";
import SingleDocDownload from "./SingleDocDownload";
import DropDown from "../Icons/drop.svg";
import DownArrow from "../Icons/DownArrow.svg";
import FileUploadLoading from "./FileUploadLoading";
import FileIsUploading from "./FileIsUploading";
import { uploadFileDocx } from "../helpers";
import Loader from "./Loader";
import UploadSuccess from "./UploadSuccess";

// import BeatLoader from "react-spinners/BeatLoader";
// import { saveAs } from "file-saver";
// import { asBlob } from "html-docx-js-typescript";
// import ConceptDesignChecklist from "../Templates/ConceptDesignChecklist";
// import SuccessAlert from "./SuccessAlert";

const addZeros = (num) => {
  return (num < 10 ? "0" : "") + num;
};

const fullDate =
  addZeros(new Date().getDate()) +
  " " +
  new Date().toLocaleString("default", {
    month: "long",
  }) +
  " " +
  new Date().getFullYear();

function Card(props) {
  const [uploadFileSpinner, setUploadFileSpinner] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [MakeChangesToFile, setMakeChangesToFile] = useState();

  useEffect(() => {
    //clear the upload status after 3 seconds only if true
    let timer = null;
    if (isUploaded) {
      timer = setTimeout(() => {
        setIsUploaded(false);
        setUploadFileSpinner(false);
      }, 10000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isUploaded]);

  const {
    onJobSelect,
    ProjectName,
    ProjectNumber,
    ProjectManagerName,
    ClientName,
    ProjectStatusName,
    ProjectStatusID,
    MelbOrAdel,
    currentJob,
  } = props;

  const uploadedFileName =
    currentJob?.ProjectNumber +
    (currentJob?.CustomFields[0]?.Value === null ||
    currentJob?.CustomFields[0]?.Value === "1"
      ? "M"
      : "A");

  const Year = currentJob?.ProjectNumber.slice(0, 2);

  const generateDocxUrl = (id) => {
    return `https://docs.google.com/document/d/${id}/edit?usp=drive_web&ouid=118424251403297522831&rtpof=true`;
  };

  async function uploadFileDocxWithSpinner(type, path) {
    setUploadFileSpinner(true);
    const data = await uploadFileDocx(type, path, uploadedFileName);
    setMakeChangesToFile(generateDocxUrl(data.id));

    setIsUploaded(true);
    setUploadFileSpinner(false);
  }

  const ClickMeForChanges = () => {
    window.open(MakeChangesToFile, "_blank");
  };

  //click button to open MakeChangesToFile URL in new tab

  const FullNumber =
    ProjectNumber + (MelbOrAdel === null || MelbOrAdel === "1" ? "M" : "A");

  //THIS FUNCTION IS CREATED TO DOWNLOAD ALL THE FILES AT ONCE WITH A CLICK, ENABLE IT BY JUST UN COMMENTING THE CODE BELOW

  // const template = ConceptDesignChecklist(
  //   ProjectName,
  //   ProjectNumber,
  //   ProjectManagerName
  // );

  // async function onClick() {
  //   const file = await asBlob(template);
  //   saveAs(file, "ConceptDesignChecklist" + FullNumber + ".docx");
  //
  // }

  async function ConceptDesignChecklistFunction() {
    await uploadFileDocxWithSpinner(
      "ConceptDesignChecklist",
      `${Year}/${uploadedFileName}/Admin/QA/Checklists`
    );

    // this function below downloads the file locally

    // const file = await asBlob(template);
    // saveAs(file, "ConceptDesign" + FullNumber + ".docx");
  }

  async function GeotechnicalTemplateFunction() {
    await uploadFileDocxWithSpinner(
      "GeotechnicalFeeRequest",
      `${Year}/${uploadedFileName}/Engineering/Geotechnical`
    );
  }
  async function SafetyAndDesignReportTemplateFunction() {
    await uploadFileDocxWithSpinner(
      "SafetyInDesignReport",
      `${Year}/${uploadedFileName}/Admin/QA/Safety in Design`
    );
  }

  async function SiteInspectionTemplateFunction() {
    await uploadFileDocxWithSpinner(
      "SiteInspectionReport",
      `${Year}/${uploadedFileName}/Engineering/Site Inspection Reports`
    );
  }

  async function OccupationalTemplateFunction() {
    await uploadFileDocxWithSpinner(
      "SiteInspectionReport",
      `${Year}/${uploadedFileName}/Admin/QA/Safety in Design`
    );
  }

  async function MatterFeeCertificate() {
    await uploadFileDocxWithSpinner(
      "Certification",
      `${Year}/${uploadedFileName}/Admin/Proposal`
    );
  }

  async function MatterFeeClash() {
    await uploadFileDocxWithSpinner(
      "ClashDetection",
      `${Year}/${uploadedFileName}/Admin/Proposal`
    );
  }

  async function FeeSubmissionFixedFee() {
    await uploadFileDocxWithSpinner(
      "FixedFee",
      `${Year}/${uploadedFileName}/Admin/Proposal`
    );
  }

  async function MatterFeeForensic() {
    await uploadFileDocxWithSpinner(
      "ForensicReport",
      `${Year}/${uploadedFileName}/Admin/Proposal`
    );
  }

  async function MatterFeePeerReview() {
    await uploadFileDocxWithSpinner(
      "PeerReview",
      `${Year}/${uploadedFileName}/Admin/Proposal`
    );
  }

  async function MatterFeePrecast() {
    await uploadFileDocxWithSpinner(
      "Precast",
      `${Year}/${uploadedFileName}/Admin/Proposal`
    );
  }

  async function MatterFeePTFloor() {
    await uploadFileDocxWithSpinner(
      "PtFloor",
      `${Year}/${uploadedFileName}/Admin/Proposal`
    );
  }

  async function MatterFeeVariation() {
    await uploadFileDocxWithSpinner(
      "FeeVariation",
      `${Year}/${uploadedFileName}/Admin/Proposal`
    );
  }

  async function MatterLetterTemplate() {
    await uploadFileDocxWithSpinner(
      "BlankLetter",
      `${Year}/${uploadedFileName}/Engineering/Correspondance/Letters`
    );
  }

  async function MatterLetterCompliance() {
    await uploadFileDocxWithSpinner(
      "MonthlyComlianceLetter",
      `${Year}/${uploadedFileName}/Engineering/Correspondance/Letters`
    );
  }

  async function MatterCertQueryCode() {
    await uploadFileDocxWithSpinner(
      "MonthlyComlianceLetter",
      `${Year}/${uploadedFileName}/CAD/Received`
      //CQL0
    );
  }
  //13
  async function MatterPeerReviewCode() {
    await uploadFileDocxWithSpinner(
      "PeerReviewReport",
      `${Year}/${uploadedFileName}/Engineering/Correspondance/Reports`
    );
  }

  async function MatterStructuralDesignBreifCode() {
    await uploadFileDocxWithSpinner(
      "StructuralDesignBrief",
      `${Year}/${uploadedFileName}/Engineering/Design brief`
    );
  }

  async function MatterCalculationDoc() {
    await uploadFileDocxWithSpinner(
      "CalculationDocument",
      `${Year}/${uploadedFileName}/Engineering/Calculations`
    );
  }

  async function CompsCalc() {
    await uploadFileDocxWithSpinner(
      "ComputationalPackage",
      `${Year}/${uploadedFileName}/Engineering/Calculations`
    );
  }

  async function MatterCivilSpecs() {
    await uploadFileDocxWithSpinner(
      "CivilSpecification",
      `${Year}/${uploadedFileName}/Engineering/Site Inspection Reports`
    );
  }

  async function MatterStructuralSpecs() {
    await uploadFileDocxWithSpinner(
      "StructuralSpecification",
      `${Year}/${uploadedFileName}/Engineering/Site Inspection Reports`
    );
  }

  async function Reg126Design() {
    await uploadFileDocxWithSpinner(
      "Reg126Design",
      `${Year}/${uploadedFileName}/Engineering/Certifications`
    );
  }

  async function Reg126Inspection() {
    await uploadFileDocxWithSpinner(
      "Reg126Review",
      `${Year}/${uploadedFileName}/Engineering/Certifications`
    );
  }

  /////////////////////////////////////////////////////////

  const [toggle, setToggle] = useState(false);
  const displayCardData = () => {
    setToggle(!toggle);
  };

  return (
    <div className="SingleCard">
      {/**
      {!isUploaded && uploadFileSpinner ? <FileUploadLoading /> : null}
      {isUploaded && !uploadFileSpinner ? <FileIsUploading /> : null} */}
      {!toggle ? (
        <div onClick={displayCardData}>
          <div className="Heading2" onClick={onJobSelect}>
            <p className="text-lg font-bold">
              {`${FullNumber} - ${ProjectName}`}
            </p>
            <p className="text-sm ">{`Manager - ${ProjectManagerName} `}</p>
            <p className="text-sm ">{`Client - ${ClientName}`}</p>
          </div>
        </div>
      ) : (
        <div>
          <div onClick={displayCardData}>
            <div className="Heading3" onClick={onJobSelect}>
              <p className="text-lg font-bold">
                {`${FullNumber} - ${ProjectName}`}
              </p>
              <p className="text-sm ">{`Manager: ${ProjectManagerName} `}</p>
              <p className="text-sm ">{`Client ${ClientName}`}</p>
            </div>
          </div>
          {/**<SingleDocDownload singleDocName="Download All" onClick={onClick} /> */}

          <div className="flex">
            <div className="SubCard ">
              <button className="RegBtn">FEE PROPOSALS</button>
              <div className="">
                <SingleDocDownload
                  singleDocName="Certification"
                  onClick={MatterFeeCertificate}
                />
                <SingleDocDownload
                  singleDocName="Clash Detection"
                  onClick={MatterFeeClash}
                />
                <SingleDocDownload
                  singleDocName="Fixed Fee"
                  onClick={FeeSubmissionFixedFee}
                />
                <SingleDocDownload
                  singleDocName="Peer Review"
                  onClick={MatterFeePeerReview}
                />
                <SingleDocDownload
                  singleDocName="Precast"
                  onClick={MatterFeePrecast}
                />
                <SingleDocDownload
                  singleDocName="PT Floors"
                  onClick={MatterFeePTFloor}
                />
                <SingleDocDownload
                  singleDocName="Fee Variation"
                  onClick={MatterFeeVariation}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="SubCard">
                <button className="RegBtn">CERTIFICATION</button>
                <div className="">
                  <SingleDocDownload
                    singleDocName="Reg126 Design"
                    onClick={Reg126Design}
                  />
                  <SingleDocDownload
                    singleDocName="Reg126 Inspection"
                    onClick={Reg126Inspection}
                  />
                </div>
              </div>
              <div className="SubCard">
                <button className="RegBtn">SPECIFICATIONS</button>

                <SingleDocDownload
                  singleDocName="Civil Specification"
                  onClick={MatterCivilSpecs}
                />
                <SingleDocDownload
                  singleDocName="Structural Specification"
                  onClick={MatterStructuralSpecs}
                />
              </div>
            </div>
            <div className="SubCard">
              <button className="RegBtn">
                REPORT
                <img src={DropDown} height={10} width={10} />
              </button>

              <SingleDocDownload
                singleDocName="Site Inspection Report"
                onClick={SiteInspectionTemplateFunction}
              />
              <SingleDocDownload
                singleDocName="Cert.Query Report"
                onClick={MatterCertQueryCode}
              />
              <SingleDocDownload
                singleDocName="Forensic Report"
                onClick={MatterFeeForensic}
              />
              <SingleDocDownload
                singleDocName="Peer Review Report"
                onClick={MatterPeerReviewCode}
              />
            </div>
            <div>
              <div className="SubCard">
                <button className="RegBtn">CALCULATIONS</button>
                <div className="">
                  <SingleDocDownload
                    singleDocName="Calculation Document"
                    onClick={MatterCalculationDoc}
                  />
                  <SingleDocDownload
                    singleDocName="Computation Package"
                    onClick={CompsCalc}
                  />
                </div>
              </div>
              <div className="SubCard">
                <button className="RegBtn">QA</button>
                <div className="">
                  <SingleDocDownload
                    singleDocName="Concept Design Check list"
                    onClick={ConceptDesignChecklistFunction}
                  />
                  <SingleDocDownload
                    singleDocName="Structural Design Brief"
                    onClick={MatterStructuralDesignBreifCode}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="SubCard">
                <button className="RegBtn">ADMIN</button>
                <div className="">
                  <SingleDocDownload
                    singleDocName="Safety in Design Report"
                    onClick={SafetyAndDesignReportTemplateFunction}
                  />

                  <SingleDocDownload
                    singleDocName="Geotechnical Fee Request"
                    onClick={GeotechnicalTemplateFunction}
                  />

                  <SingleDocDownload
                    singleDocName="Risk Management Report"
                    onClick={OccupationalTemplateFunction}
                  />
                </div>
              </div>
              <div className="SubCard">
                <button className="RegBtn">LETTERS</button>
                <div className="">
                  <SingleDocDownload
                    singleDocName="Blank Letter"
                    onClick={MatterLetterTemplate}
                  />
                  <SingleDocDownload
                    singleDocName="Monthly Compliance Letter"
                    onClick={MatterLetterCompliance}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="h-12 rounded-full mt-2 flex text-center w-full">
            <div className="text-black  items-center m-auto">
              {!isUploaded && uploadFileSpinner ? (
                <Loader loaderType="Uploading.." />
              ) : null}
              <div className="flex justify-center">
                {isUploaded && !uploadFileSpinner ? (
                  <UploadSuccess successName="Uploaded" />
                ) : null}
                {isUploaded && !uploadFileSpinner ? (
                  <div
                    className="bg-[#63755D] text-white rounded-2xl p-2 animate-bounce"
                    onClick={ClickMeForChanges}>
                    File Generated
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      {/**{isUploaded && !uploadFileSpinner ? (
        <div className="flex flex-col justify-center items-center">
          <img src={DownArrow} width={30} className="animate-bounce" />
          <div
            className="bg-[#e76929] text-black rounded-full w-full"
            onClick={ClickMeForChanges}>
            Click here to Make Changes to the Generated File!
          </div>
        </div>
      ) : null} */}
    </div>
  );
}

export default Card;
