import React, { useRef, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "./Login.css";
import User from "../Icons/user.svg";
import Lock from "../Icons/lock.svg";

function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  if (currentUser) {
    return <Navigate to="/dashboard/profile" replace />;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/dashboard/profile");
    } catch {
      setError("Failed to Login");
    }
    setLoading(false);
  }

  return (
    <div>
      <div class="bg-img">
        {error ? (
          <div
            class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
            role="alert">
            <span class="font-medium">Something went Wrong!</span> Inocrrect
            username or password
          </div>
        ) : null}
        <div class="content">
          <header>Login Here</header>
          <form className="formmm" onSubmit={handleSubmit}>
            <div class="field">
              <img src={User} width={35} className="p-1 mr-2" />
              <input type="text" required placeholder="Email" ref={emailRef} />
            </div>
            <div class="field space">
              <img src={Lock} width={30} className="p-1 mr-2" />
              <input
                type="password"
                class="pass-key"
                required
                placeholder="Password"
                ref={passwordRef}
              />
            </div>
            <div class="pass">
              <a href="abcd">Forgot Password?</a>
            </div>
            <div class="field">
              <input type="submit" value="LOGIN" />
            </div>
          </form>
          <div class="signup">
            Don't have account? &nbsp;
            <a href="sds">Signup Now</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
