import React, { useState } from "react";
import "./UploadFile.css";
import upload from "../Icons/upload.svg";

function UploadFile(props) {
  const [file, setFile] = useState(undefined);

  const onFileSelect = (e) => {
    const file = e.target.files[0];
    setFile(undefined);
    props.onUpload(file);
  };

  return (
    <div className="outer">
      <p className="fileName">{props.title}</p>
      <div className="flex  bg-grey-lighter">
        <label className="label">
          <img src={upload} width={30} />

          <input
            value={file}
            type="file"
            class="hidden"
            onChange={onFileSelect}
          />
        </label>
      </div>
    </div>
  );
}

export default UploadFile;
