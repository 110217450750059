import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../Icons/MatterLogo.svg";
import { useAuth } from "../Authentication/AuthContext";
import "./Navbar.css";
import Logoutt from "../Icons/Logoutt.svg";

function Navbar() {
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      navigate("/");
    } catch {
      setError("Failed to Log out");
    }
  }

  const handleClick = () => {
    setToggle(!toggle);
  };
  const [navbar, setNavbar] = useState(false);

  return (
    <div className="fullNav">
      <div className="matterLogo">
        <Link to="/dashboard/profile" onClick={handleClick}>
          <img src={Logo} alt="" className="h-20 " />
        </Link>
      </div>

      <div>
        <div
          onClick={() => setNavbar(!navbar)}
          active={toggle}
          className="nestedLinks">
          <div className="LinkPage">
            <Link
              to="/dashboard/profile"
              onClick={handleClick}
              className={
                location.pathname === "/dashboard/profile" ? "active" : ""
              }>
              Profile
            </Link>
          </div>
          <div className="LinkPage">
            <Link
              to="/dashboard/received-docs"
              onClick={handleClick}
              className={
                location.pathname === "/dashboard/received-docs" ? "active" : ""
              }>
              Received Docs
            </Link>
          </div>
          <div className="LinkPage">
            <Link
              to="/dashboard/admin-docs"
              onClick={handleClick}
              className={
                location.pathname === "/dashboard/admin-docs" ? "active" : ""
              }>
              Admin Docs
            </Link>
          </div>
          <div className="LinkPage">
            <Link
              to="/dashboard/qa"
              onClick={handleClick}
              className={location.pathname === "/dashboard/qa" ? "active" : ""}>
              QA
            </Link>
          </div>
          <div className="LinkPage">
            <Link
              to="/dashboard/monthly-reporting"
              onClick={handleClick}
              className={
                location.pathname === "/dashboard/monthly-reporting"
                  ? "active"
                  : ""
              }>
              Monthly Reporting
            </Link>
          </div>
        </div>
      </div>
      <div>
        <Link onClick={handleLogout}>
          <img src={Logoutt} alt="" className="logoutbtn" />
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
